import React from "react";
import { getUrlBackEnd, getTokenLogin, setToken, removeToken, setUser, setColorApp } from "../data/SessionVar.jsx";
import { ExecuteDataSourceSync } from "../framework/AstroNetREST";
import { isNullOrEmptySpace, convertToBase64Decode, convertToBase64Encode } from '../framework/Functions/AstroNetUtility'
import AstroNetCard from '../framework/AstroNetCard';
import ErrorBoundary from "../form/Error/ErrorBoundary"

import { Route, Switch } from "react-router-dom";
import routes from "./../../routes.js";

import {
    Row, Col, Button, Card, CardBody, Container,
    Modal, ModalBody, ModalHeader, ModalFooter, Input, FormGroup, Label,

} from "reactstrap";
import { MdPerson } from "react-icons/md";
import { ExecuteDataSource } from '../framework/AstroNetREST';
import Load from "../layout/PageProgress.jsx";
import base64 from 'base-64';
import utf8 from 'utf8';
import logoastroSvg from '../../assets/logoastro.svg'

var key = convertToBase64Encode(window.location.hostname)
class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalLogin: false,
            user: "",
            pass: "",
            listCard: [],
            showCard: true,
            savePass: false,
            title: ""
        }

        this.onChangeUser = this.onChangeUser.bind(this)
        this.onChangePass = this.onChangePass.bind(this)
        this.onChangeSavePass = this.onChangeSavePass.bind(this)
        this.onChangeConnect = this.onChangeConnect.bind(this)

        if (!isNullOrEmptySpace(this.props.match.params.token) && this.props.match.params.token !== "instancehandle" &&
            !isNullOrEmptySpace(this.props.match.params.redirect)) {
            var token = this.props.match.params.token
            setToken(token)
            var obj = {
                DataSourceMethod: 'get',
                DataSourceUrl: getUrlBackEnd() + "user/get",
                DataSourceHeader: {
                    'Content-Type': 'application/json',
                    Authorization: getTokenLogin(),
                }
            }
            var result = {};

            try {
                result = ExecuteDataSourceSync(obj)
            } catch (e) {
                console.error(e);
            }

            if (isNullOrEmptySpace(result) || isNullOrEmptySpace(result.name)) {
                removeToken();
                window.location.href = "/login"
            }
            else {
                setUser(result)
                window.location.href = convertToBase64Decode(this.props.match.params.redirect)

            }
            return
        }

        removeToken();
    }

    componentDidMount() {
        this.getProcessStart();
        var obj = {
            DataSourceMethod: 'get',
            DataSourceUrl: getUrlBackEnd() + "user/get/color",
            DataSourceHeader: {
                'Content-Type': 'application/json'
            },
        }
        var result = ExecuteDataSourceSync(obj)
        if (!isNullOrEmptySpace(result) && !result.defaultScreen) {
            setColorApp(result.color)
        }
        if (!isNullOrEmptySpace(result) && !isNullOrEmptySpace(result.name)) {
            this.setState({ title: result.name })
        }
    }

    convertToBase64(user, pass) {
        var token = user + ";" + pass
        var bytes = utf8.encode(token);
        var encoded = base64.encode(bytes);
        return encoded;
    }

    getProcessStart = () => {
        var _this = this
        var obj = {
            DataSourceMethod: 'get',
            DataSourceUrl: getUrlBackEnd() + 'bpmn/listallstart',
            DataSourceHeader: {
                'Content-Type': 'application/json',
                Authorization: getTokenLogin()
            }
        }
        ExecuteDataSource(obj).then(function (response) {
            if (!isNullOrEmptySpace(response) && response.length > 0) {
                var listNewCard = response.map((item) => ({
                    value: item.id,
                    body: item.name,
                    content: item.description,
                    icon: item.icon,
                    version: item.version,
                    footer: "Abrir"
                }));
                _this.setState({ listCard: listNewCard })
            }
            else {
                _this.setState({ modalLogin: true, message: "" })
            }
        }).catch(function (error) {
            console.error(error)
            _this.setState({ error: error })
        })
    }

    onChangeUser(event) {
        var value = event.target.value
        this.setState({ user: value });
    }

    onChangePass(event) {
        var value = event.target.value
        this.setState({ pass: value });
    }

    onChangeSavePass(event) {
        var value = event.target.checked
        this.setState({ savePass: value });
    }

    onChangeConnect(event) {
        var value = event.target.checked
        this.setState({ connect: value });
    }

    clicklogin() {
        if (isNullOrEmptySpace(this.state.user) || isNullOrEmptySpace(this.state.pass)) {
            this.setState({ message: "Usuario ou senha invalido" })
            return
        }
        var token = this.convertToBase64(this.state.user, this.state.pass)

        var obj = {
            DataSourceMethod: 'post',
            DataSourceUrl: getUrlBackEnd() + "user/login/",
            DataSourceHeader: {
                'Content-Type': 'application/json'
            },
            DataSourceData: {
                token: token
            }
        }
        var result = ExecuteDataSourceSync(obj)


        if (!isNullOrEmptySpace(result)) {
            if (this.state.connect) {
                localStorage.setItem(key + ".msTPF", result)
            }
            else {
                localStorage.setItem(key + ".msTPF", "")
            }

            if (this.state.savePass) {
                var savePass = { user: this.state.user, pass: this.state.pass, savePass: this.state.savePass }
                localStorage.setItem("astPF", JSON.stringify(savePass))
            }
            else {
                localStorage.setItem("astPF", "")
            }
            setToken(result)
            this.props.history.push("/admin");
        }
        else {
            this.setState({ message: "Usuario ou senha invalido" })
        }
    }

    renderModalLogin() {
        if (!isNullOrEmptySpace(localStorage.getItem(key + ".msTPF"))) {
            setToken(localStorage.getItem(key + ".msTPF"))
            this.props.history.push("/admin");
        }

        if (this.state.modalLogin && !isNullOrEmptySpace(localStorage.getItem("astPF"))) {
            var cred = JSON.parse(localStorage.getItem("astPF"));
            if ((isNullOrEmptySpace(this.state.user) && isNullOrEmptySpace(this.state.pass)) && !isNullOrEmptySpace(cred) && !isNullOrEmptySpace(cred.user)) {
                this.setState({ user: cred.user, pass: cred.pass, savePass: cred.savePass })
            }
        }
        return (
            <Modal isOpen={this.state.modalLogin}>
                <ModalHeader className="color-background">
                    <img style={{ height: "50px" }} src={logoastroSvg} alt="logo" />
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Usuario</Label>
                        <Input type="text" id="user"
                            className="form-control"
                            placeholder="Informe seu usuário"
                            onChange={this.onChangeUser}
                            value={this.state.user} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Senha</Label>
                        <Input type="password" id="pass"
                            className="form-control"
                            placeholder="Informe a senha"
                            onChange={this.onChangePass}
                            value={this.state.pass} />
                    </FormGroup>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Input type="checkbox" id="savepass" onChange={this.onChangeSavePass} checked={this.state.savePass} />
                                <Label>Salvar Senha</Label>
                            </FormGroup>
                        </Col>
                        <Col md={6} className="d-flex align-items-end flex-column">
                            <FormGroup>
                                <Input type="checkbox" id="connect" onChange={this.onChangeConnect} checked={this.state.connect} />
                                <Label>Manter conectado</Label>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <p style={{ color: "#FF0017" }}>
                            {this.state.message}
                        </p>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => { this.setState(prevState => ({ modalLogin: !prevState.modalLogin, error: "", message: "" })) }}>Cancelar</Button>
                    <Button color="deore" className="btn-primary" onClick={() => this.clicklogin()}>Entrar</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderCard() {

        if (this.state.listCard.length === 0)
            return

        return (
            <AstroNetCard listItem={this.state.listCard}
                size={4}
                onSubmitEvent={(cardSelected) => (this.actionCardSelected(cardSelected))}
                classNameCard
                classNameCardHeard
                classNameCardBody
                classNameCardFooter />
        )
    }

    actionCardSelected(card) {
        this.props.history.push("/login/instancehandle/" + card.selected.value + "/new/false");
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/login") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    typeUrl = () => {
        var uri = window.location.href
        var qtParam = uri.split('/');
        return qtParam.length < 6 ? false : true;
    }

    render() {
        if (!isNullOrEmptySpace(this.state.error))
            throw this.state.error;
        return (
            <ErrorBoundary>
                <Container>
                    <Row>
                        <Col>
                            <Card className="color-background">
                                <CardBody>
                                    <div className="clearfix">
                                        {isNullOrEmptySpace(this.state.title) ?
                                            <div className="float-left">
                                                <img style={{ height: "50px" }} src={logoastroSvg} alt="logo" />
                                            </div> :
                                            <div className="float-left" style={{ fontSize: "40px" }}><b>{this.state.title}</b></div>
                                        }
                                        <div className="float-right">
                                            <Button className="color-background-transparent"
                                                onClick={() => { this.setState(prevState => ({ modalLogin: !prevState.modalLogin, error: "", message: "" })) }}>
                                                <MdPerson color="#ffff" size={30} />
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Container fluid>
                        <Row hidden={this.typeUrl()}>
                            <Col>

                                {this.renderCard()}

                            </Col>
                        </Row>
                    </Container>
                </Container>
                <React.Suspense fallback={<Load />}>
                    <Switch>
                        {this.getRoutes(routes)}
                    </Switch>
                </React.Suspense>
                {this.renderModalLogin()}
            </ErrorBoundary>
        )
    }

}

export default Login