import React from "react";
import { Navbar } from "reactstrap";
import { getUser } from "../data/SessionVar.jsx";
import { isNullOrEmptySpace, convertToBase64Encode } from '../framework/Functions/AstroNetUtility';
class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valueShow: "sidebar sidebar-offcanvas"
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.valueMenuShow !== nextProps.valueMenuShow) {
            if (nextProps.valueMenuShow) {
                this.setState({
                    valueShow: "sidebar sidebar-offcanvas.active"
                });
            } else {
                this.setState({
                    valueShow: "sidebar sidebar-offcanvas"
                });
            }
        }
    }

    renderMenuItem() {
        var user = getUser()
        if (isNullOrEmptySpace(user.ListMenu) || user.ListMenu.length === 0)
            return
        return (<>

            {user.ListMenu.map((x, i) => {
                if (x.Url === "*") {
                    return (
                        <li key={i} className="nav-item-menu-category">{x.Name}</li>
                    )
                }
                else if (x.Url.includes("http")) {
                    return (
                        <li key={i} className="nav-item">
                            <a className="nav-link" href={"/admin/formframe/" + convertToBase64Encode(x.Url)}>
                                <span className="menu-title">{x.Name}</span>
                            </a>
                        </li>
                    )
                }
                else {
                    return (
                        <li key={i} className="nav-item-menu" onClick={() => {window.location.href = x.Url}}>
                            <span>{x.Name}</span>
                        </li>
                    )
                }
            })}
        </>
        )
    }

    render() {
        return (
            <div className={this.state.valueShow} id="sidebar" hidden={!this.props.valueMenuShow}>
                <ul className="nav-menu">
                    {this.renderMenuItem()}
                </ul>
            </div>
        )
    }
}
export default SideBar
