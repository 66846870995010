import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import { isNullOrEmptySpace } from './Functions/AstroNetUtility'
import parser from 'html-react-parser'
import { Icon } from './AstroNetIcons';
class AstroNetCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClickCard(item, event) {
        this.submitEventCard(item, event)
        return item
    }

    submitEventCard(item, event) {
        const { onSubmitEvent } = this.props;
        if (onSubmitEvent) {
            onSubmitEvent({
                event: event,
                selected: item
            });
        }
    }

    renderCard(item) {
        return (
            <Card onClick={this.onClickCard.bind(this, item)} style={{ cursor: "pointer", border: "1px solid rgba(0, 0, 0, 0.2)" }}>
                <CardHeader hidden={isNullOrEmptySpace(item.header)} >
                    {isNullOrEmptySpace(item.header) ? "" : parser(item.header)}

                </CardHeader>
                <CardBody hidden={isNullOrEmptySpace(item.body)} >
                    <h5 style={{borderBottom: "solid 1px #ffaf00", color: "#3e4595ed", marginBottom: "2rem"}}>
                        <Icon iconName={item.icon} size={40}
                            style={{ marginLeft: "5px", marginRight: "10px", color: "#3e4595ed" }} />
                        <b>{isNullOrEmptySpace(item.body) ? "" : parser(item.body)}</b>
                    </h5>
                    <span>{isNullOrEmptySpace(item.content) ? "" : parser(item.content)}</span>
                </CardBody>
                <CardFooter style={{ textAlign: "center", backgroundColor: "#3e4595b6", color: "#ffff", marginTop: "1rem" }} hidden={isNullOrEmptySpace(item.footer)} >
                    {isNullOrEmptySpace(item.footer) ? "" : parser(item.footer)}
                </CardFooter>
            </Card>
        )
    }

    renderListCard(list) {
        return (
            <Row>
                {list.map((item, i) => {
                    return (
                        <Col sm={this.props.size} key={i} style={{ marginBottom: "20px" }} >
                            {this.renderCard(item)}
                        </Col>
                    )
                })}
            </Row>
        )
    }
    render() {
        if (this.props.listItem.length > 0) {
            return (<>{this.renderListCard(this.props.listItem)}</>)
        } else {
            return (<>{this.renderCard(this.props.item)}</>)
        }
    }
}
AstroNetCard.defaultProps = {
    size: 12,
    listItem: [],
    item: {}
}
AstroNetCard.propTypes = {
    listItem: PropTypes.array,
    size: PropTypes.number,
    item: PropTypes.object,
    onSubmitEvent: PropTypes.func
}

export default AstroNetCard;
