import axios from 'axios';
import { replaceParameter, isNullOrEmptySpace } from './Functions/AstroNetUtility'
import RequestSync from 'sync-request'

export async function ExecuteDataSource(obj, form = null, type = null) {
    var config = {};
    let dateStart = new Date()
    if (!isNullOrEmptySpace(form))
        obj = replaceParameter(obj, form, type)

    if (!isNullOrEmptySpace(obj.DataSourceMethod))
        config["method"] = obj.DataSourceMethod

    if (!isNullOrEmptySpace(obj.DataSourceUrl))
        config["url"] = obj.DataSourceUrl

    if (!isNullOrEmptySpace(obj.DataSourceBaseURL))
        config["baseURL"] = obj.DataSourceBaseURL

    if (!isNullOrEmptySpace(obj.DataSourceEncoding))
        config["responseEncoding"] = obj.DataSourceEncoding

    if (!isNullOrEmptySpace(obj.DataSourceContentType))
        config["responseEncoding"] = obj.DataSourceContentType

    if (!isNullOrEmptySpace(obj.DataSourceHeader))
        config["headers"] = obj.DataSourceHeader

    if (!isNullOrEmptySpace(obj.DataSourceData))
        config["data"] = obj.DataSourceData

    if (!isNullOrEmptySpace(obj.DataSourceTimeout))
        config["timeout"] = obj.DataSourceTimeout

    if (!isNullOrEmptySpace(obj.DataSourceAuth))
        config["auth"] = obj.DataSourceAuth

    if (!isNullOrEmptySpace(obj.DataSourceProxy))
        config["proxy"] = obj.DataSourceProxy


    return await asynAxios(config).then((response) => {
        if (!isNullOrEmptySpace(window.LOG) && window.LOG) {
            let dateEnd = new Date()
            const diffInSeconds = (dateEnd.getTime() - dateStart.getTime()) / 1000;
            console.log(`URL: ${config["url"]} tempo de Execução ${diffInSeconds}`)
        }
        return response
    }).catch(function (error) {
        let dateEnd = new Date()
        const diffInSeconds = (dateEnd.getTime() - dateStart.getTime()) / 1000;
        console.log(`URL: ${config["url"]} tempo de Execução ${diffInSeconds}`)
        return Promise.reject(error);
    })
}

export function ExecuteDataSourceSync(obj) {
    var config = {};
    var url = "";
    var method = "";
    let dateStart = new Date()
    if (!isNullOrEmptySpace(obj.DataSourceHeader))
        config["headers"] = obj.DataSourceHeader

    if (!isNullOrEmptySpace(obj.DataSourceMethod))
        method = obj.DataSourceMethod

    if (!isNullOrEmptySpace(obj.DataSourceUrl))
        url = obj.DataSourceUrl

    if (!isNullOrEmptySpace(obj.DataSourceData))
        config["json"] = obj.DataSourceData

    var resp = RequestSync(method.toUpperCase(), url, config)

    if (!isNullOrEmptySpace(window.LOG) && window.LOG) {
        let dateEnd = new Date()
        const diffInSeconds = (dateEnd.getTime() - dateStart.getTime()) / 1000;
        console.log(`URL: ${url} tempo de Execução ${diffInSeconds}`)
    }

    if (resp.statusCode === 404) {
        console.error(obj)
        throw new Error("URL não encontrada")
    }
    else
        if (resp.statusCode === 401) {
            console.error(obj)
            throw new Error("Não autorizado")
        }

    if (!isNullOrEmptySpace(resp.getBody('utf8'))) {
        try {
            return JSON.parse(resp.getBody('utf8'))
        }
        catch (e) {
            return resp.getBody('utf8')
        }

    }
    else
        return "";
}


const asynAxios = async (config) => {

    var resp = await axios(config)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return Promise.reject(error);
        });
    var respData = await resp.data
    return respData
}
