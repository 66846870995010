import React from 'react';

//import Dashboard from "./components/form/Dashboard.jsx";

//import BpmHome from "./components/form/Bpm/BpmnModelerComponentHome";
//import BpmHandle from "./components/form/Bpm/BpmnModelerComponentHandle";

const Dashboard = React.lazy(() => import('./components/form/Dashboard.jsx'));
const BpmHome = React.lazy(() => import('./components/form/Bpm/BpmnModelerComponentHome.jsx'));
const BpmHandle = React.lazy(() => import('./components/form/Bpm/BpmnModelerComponentHandle.jsx'));


//import FormInj from "./components/form/FormInject";
const FormInj = React.lazy(() => import('./components/form/FormInject.jsx'));

//import FormDataSourceHome from './components/form/DataSource/DataSourceHome';
const FormDataSourceHome = React.lazy(() => import('./components/form/DataSource/DataSourceHome.jsx'));

//import FormDataSourceHandle from './components/form/DataSource/DataSourceHandle';
const FormDataSourceHandle = React.lazy(() => import('./components/form/DataSource/DataSourceHandle.jsx'));

//import DrawFormHome from './components/form/DrawForm/DrawFormHome';
const DrawFormHome = React.lazy(() => import('./components/form/DrawForm/DrawFormHome.jsx'));

//import FormInstanceHomeList from './components/form/Instance/FormInstanceHomeList';
const FormInstanceHomeList = React.lazy(() => import('./components/form/Instance/FormInstanceHomeList.jsx'));

//import FormInstanceHome from './components/form/Instance/FormInstanceHome';
const FormInstanceHome = React.lazy(() => import('./components/form/Instance/FormInstanceHome.jsx'));

//import FormInstanceHandle from './components/form/Instance/FormInstanceHandle';
const FormInstanceHandlePage = React.lazy(() => import('./components/form/Instance/FormInstanceHandlePage'));

//import FormInstanceTest from './components/form/Instance/FormInstanceTest';
const FormInstanceTest = React.lazy(() => import('./components/form/Instance/FormInstanceTest.jsx'));

//import FormDepartmentHome from './components/form/Department/FormDepartmentHome';
const FormDepartmentHome = React.lazy(() => import('./components/form/Department/FormDepartmentHome.jsx'));

//import FormDepartmentHandle from './components/form/Department/FormDepartmentHandle';
const FormDepartmentHandle = React.lazy(() => import('./components/form/Department/FormDepartmentHandle.jsx'));

//import FormRoleHome from './components/form/Role/FormRoleHome';
const FormRoleHome = React.lazy(() => import('./components/form/Role/FormRoleHome.jsx'));

//import FormRoleHandle from './components/form/Role/FormRoleHandle';
const FormRoleHandle = React.lazy(() => import('./components/form/Role/FormRoleHandle.jsx'));

//import FormTemplateDocHome from './components/form/Template/FormTemplateDocHome';
const FormTemplateDocHome = React.lazy(() => import('./components/form/Template/FormTemplateDocHome.jsx'));

//import FormTemplateDocHandle from './components/form/Template/FormTemplateDocHandle';
const FormTemplateDocHandle = React.lazy(() => import('./components/form/Template/FormTemplateDocHandle.jsx'));

//import FormConfiguration from './components/form/FormConfiguration';
const FormConfiguration = React.lazy(() => import('./components/form/FormConfiguration.jsx'));

//import FormUserGroup from './components/form/Security/FormUserGroup';
const FormUserGroup = React.lazy(() => import('./components/form/Security/FormUserGroup.jsx'));

//import FormMenu from './components/form/Security/FormMenu';
const FormMenu = React.lazy(() => import('./components/form/Security/FormMenu.jsx'));

//import FormUserHome from './components/form/User/FormUserHome';
const FormUserHome = React.lazy(() => import('./components/form/User/FormUserHome.jsx'));

//import FormUserHandle from './components/form/User/FormUserHandle';
const FormUserHandle = React.lazy(() => import('./components/form/User/FormUserHandle.jsx'));

//import ReportInstance from './components/form/Report/Instance/ReportInstance';
const ReportInstance = React.lazy(() => import('./components/form/Report/Instance/ReportInstance.jsx'));

//import ChartEditorHome from './components/form/Chart/EditorIndicatorHome';
const ChartEditorHome = React.lazy(() => import('./components/form/Chart/EditorIndicatorHome.jsx'));

//import ChartEditorHandle from './components/form/Chart/EditorIndicatorHandle';
const ChartEditorHandle = React.lazy(() => import('./components/form/Chart/EditorIndicatorHandle.jsx'));

//import Desk from './components/form/Dashboard/Desk';
const Desk = React.lazy(() => import('./components/form/Dashboard/Desk'));

//import ViewInstance from './components/form/Report/Instance/ViewInstance';
const ViewInstancePage = React.lazy(() => import('./components/form/Report/Instance/ViewInstancePage.jsx'));

//import KanbanBoardInstance from './components/form/Instance/KanbanBoardInstance';
const KanbanBoardInstance = React.lazy(() => import('./components/form/Instance/Kanban/KanbanBoardInstance.jsx'));

//import FormIframe from './components/form/MicroFrontEnd/FormIframe';
const FormIframe = React.lazy(() => import('./components/form/MicroFrontEnd/FormIframe.jsx'));

var routes = [{
    path: "/graphic",
    name: "Grafico",
    icon: "ni ni-tv-2 text-primary",
    component: Desk,
    layout: "/admin"
  },
  {
    path: "/viewkanban",
    name: "Kanban",
    icon: "ni ni-tv-2 text-primary",
    component: KanbanBoardInstance,
    layout: "/admin"
  },
  {
    path: "/viewkanbanid/:idbpm",
    name: "Kanban",
    icon: "ni ni-tv-2 text-primary",
    component: KanbanBoardInstance,
    layout: "/admin"
  },
  {
    path: "/viewinstance/:idinstance",
    name: "Instancia",
    icon: "ni ni-tv-2 text-primary",
    component: ViewInstancePage,
    layout: "/admin"
  },
  {
    path: "/instancetest/:idbpm",
    name: "Teste de Processos",
    icon: "ni ni-tv-2 text-primary",
    component: FormInstanceTest,
    layout: "/expand"
  },
  {
    path: "/instancehome/",
    name: "Minhas Atividades",
    icon: "ni ni-tv-2 text-primary",
    component: FormInstanceHomeList,
    layout: "/admin"
  },
  {
    path: "/instancehandle/:idbpm/:idtask/:test",
    name: "Tarefa",
    icon: "ni ni-tv-2 text-primary",
    component: FormInstanceHandlePage,
    layout: "/expand"
  },
  {
    path: "/instancehandle/:idbpm/:idtask/:test",
    name: "Tarefa",
    icon: "ni ni-tv-2 text-primary",
    component: FormInstanceHandlePage,
    layout: "/admin"
  },
  {
    path: "/instancehandle/:idbpm/:idtask/:test",
    name: "Tarefa",
    icon: "ni ni-tv-2 text-primary",
    component: FormInstanceHandlePage,
    layout: "/login"
  },
  {
    path: "/instancehandle/:idbpm/:idtask/:code",
    name: "Tarefa",
    icon: "ni ni-tv-2 text-primary",
    component: FormInstanceHandlePage,
    layout: "/flow"
  },
  {
    path: "/dashboard",
    name: "Relatório rápido",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/relgeneral",
    name: "Relatórios",
    icon: "ni ni-tv-2 text-primary",
    component: ReportInstance,
    layout: "/admin"
  },
  //---------
  {
    path: "/form",
    name: "Formulário",
    icon: "ni ni-tv-2 text-primary",
    component: DrawFormHome,
    layout: "/admin"
  },
  {
    path: "/bpmhome",
    name: "Editar fluxo",
    icon: "ni ni-tv-2 text-primary",
    component: BpmHome,
    layout: "/admin"
  },
  {
    path: "/bpmhandle/:id",
    name: "Editando fluxo",
    icon: "ni ni-tv-2 text-primary",
    component: BpmHandle,
    layout: "/admin"
  },
  {
    path: "/bpmhandle/:id",
    name: "Editando fluxo",
    icon: "ni ni-tv-2 text-primary",
    component: BpmHandle,
    layout: "/expand"
  },
  {
    path: "/datasourcehome",
    name: "Editar conexão",
    icon: "ni ni-tv-2 text-primary",
    component: FormDataSourceHome,
    layout: "/admin"
  },
  {
    path: "/datasourcehandle/:id",
    name: "Editando conexão",
    icon: "ni ni-tv-2 text-primary",
    component: FormDataSourceHandle,
    layout: "/admin"
  },
  {
    path: "/departmenthome",
    name: "Departamentos",
    icon: "ni ni-tv-2 text-primary",
    component: FormDepartmentHome,
    layout: "/admin"
  },
  {
    path: "/departmenthandle/:id",
    name: "Departamentos",
    icon: "ni ni-tv-2 text-primary",
    component: FormDepartmentHandle,
    layout: "/admin"
  },
  {
    path: "/rolehome",
    name: "Função",
    icon: "ni ni-tv-2 text-primary",
    component: FormRoleHome,
    layout: "/admin"
  },
  {
    path: "/rolehandle/:id",
    name: "Função",
    icon: "ni ni-tv-2 text-primary",
    component: FormRoleHandle,
    layout: "/admin"
  },
  {
    path: "/templatedoc",
    name: "Editar modelos",
    icon: "ni ni-tv-2 text-primary",
    component: FormTemplateDocHome,
    layout: "/admin"
  },
  {
    path: "/templatedochandle/:iddoc",
    name: "Editando Modelo",
    icon: "ni ni-tv-2 text-primary",
    component: FormTemplateDocHandle,
    layout: "/admin"
  },
  {
    path: "/charteditorhome",
    name: "Editar gráfico",
    icon: "ni ni-tv-2 text-primary",
    component: ChartEditorHome,
    layout: "/admin"
  },
  {
    path: "/charteditorhandle/:id",
    name: "Editando gráfico",
    icon: "ni ni-tv-2 text-primary",
    component: ChartEditorHandle,
    layout: "/admin"
  },
  {
    path: "/menu",
    name: "Menu",
    icon: "ni ni-tv-2 text-primary",
    component: FormMenu,
    layout: "/admin"
  },
  {
    path: "/usergroup",
    name: "Grupos",
    icon: "ni ni-tv-2 text-primary",
    component: FormUserGroup,
    layout: "/admin"
  },
  {
    path: "/userhome",
    name: "Usuários",
    icon: "ni ni-tv-2 text-primary",
    component: FormUserHome,
    layout: "/admin"
  },
  {
    path: "/userhandle/:id",
    name: "Usuários",
    icon: "ni ni-tv-2 text-primary",
    component: FormUserHandle,
    layout: "/admin"
  },
  {
    path: "/configuration",
    name: "FormConfiguration",
    icon: "ni ni-tv-2 text-primary",
    component: FormConfiguration,
    layout: "/admin"
  },
  {
    path: "/forminj",
    name: "FormInj",
    icon: "ni ni-tv-2 text-primary",
    component: FormInj,
    layout: "/admin"
  },
  {
    path: "/formframe/:uri",
    name: "FormFrame",
    icon: "ni ni-tv-2 text-primary",
    component: FormIframe,
    layout: "/admin"
  },

  
];
export default routes;