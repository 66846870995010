import React from 'react'
import { Card, CardBody, Col, Container, Modal, Row } from 'reactstrap'
import colors1Gif from '../../assets/colors1.gif'
const Load = () => {

    return (
        <Modal isOpen={true}>
            <CardBody>
                <Row >
                    <Col className='d-flex align-items-center justify-content-center'>
                        <img style={{ height: "50px" }} src={colors1Gif} alt="logo" />
                    </Col>
                </Row>
                <Row >
                    <Col className='d-flex align-items-center justify-content-center'>
                        <span><b>Aguarde...</b></span>
                    </Col>
                </Row>
            </CardBody>
        </Modal>
    )

    return (
        <Container className="d-flex justify-content-center col-form-label">
            <Card style={{ borderRadius: "2rem", width: "250px", height: "200px", padding: "20px", cursor: "pointer" }}>
                <CardBody>
                    <Col>
                        <Row className='d-flex align-items-center justify-content-center col-form-label'>
                            <img style={{ height: "50px" }} src={colors1Gif} alt="logo" />
                        </Row>
                        <Row className='d-flex align-items-center justify-content-center col-form-label'>
                            <span><b>Aguarde...</b></span>
                        </Row>
                    </Col>
                </CardBody>
            </Card>
        </Container>
    )
}
export default Load