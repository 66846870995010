import React from 'react'
import Topbar from "./../header/TopBar.jsx"
import Sidebar from "./../side/SideBar.jsx"
import Footerbar from "./../footer/FooterBar.jsx"
import { Container } from "reactstrap";
import { Route, Switch } from "react-router-dom";
import routes from "./../../routes.js";
import { islogin, setColor, signalRConnection } from "../data/SessionVar.jsx";
import { isNullOrEmptySpace, refresh } from "../framework/Functions/AstroNetUtility";
import { ExecuteDataSourceSync } from "../framework/AstroNetREST";
import ErrorBoundary from "../form/Error/ErrorBoundary"
import { getUrlBackEnd, getTokenLogin, getUser, setUser, removeToken } from "../data/SessionVar.jsx";
import Load from './PageProgress.jsx';

class Admin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            valueMenuShow: true,
            refresh:true
        }
       
    }

    componentDidMount =()=> {
        this.getUserLogin()
        signalRConnection(getUser().User, getTokenLogin())
        islogin(this)
        setColor();
        var larguraDaTela = window.innerWidth;
        if (larguraDaTela < 1000)
            this.setState({valueMenuShow: false})
    }

    getUserLogin() {
        //Aqui se estiver fazendo muito post
        if (!isNullOrEmptySpace(getUser()) && !isNullOrEmptySpace(getUser().Name))
            return

        var obj = {
            DataSourceMethod: 'get',
            DataSourceUrl: getUrlBackEnd() + "user/get",
            DataSourceHeader: {
                'Content-Type': 'application/json',
                Authorization: getTokenLogin(),
            }
        }
        var result = {};

        try {
            result = ExecuteDataSourceSync(obj)
        } catch (e) {
        }

        if (isNullOrEmptySpace(result) || isNullOrEmptySpace(result.Id)) {
            removeToken();
            window.location.href = "/login"

        }
        else {
            setUser(result)
            this.setState(prev=> ({refresh:!prev.refresh }))
            console.log("login user")
        }



    }

    getSelectedMenu(event) {
        this.setState({ valueMenuShow: event.value })
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    render() {
        if (!isNullOrEmptySpace(this.state.error))
            throw this.state.error;

       
        return (
            <ErrorBoundary>
                <div className="container-scroller">
                    <Topbar valueMenuShow={this.state.valueMenuShow} getSelectedMenu={(e) => this.getSelectedMenu(e)}></Topbar>
                    <Container className="page-body-wrapper" >
                        <Sidebar valueMenuShow={this.state.valueMenuShow}></Sidebar>
                        <React.Suspense fallback={<Load />}>
                            <Switch>
                                {this.getRoutes(routes)}
                            </Switch>
                        </React.Suspense>
                    </Container>
                    <Footerbar></Footerbar>
                </div>
            </ErrorBoundary>
        )
    }
}
export default Admin


