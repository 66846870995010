import moment from "moment";
import React from "react";
import {
  FormGroup, Container, Card, CardHeader, CardBody, Label, Col, Row, Button, Modal, ModalBody, ModalHeader,
} from "reactstrap";
import { getTokenLogin, getUrlBackEnd, getUser } from "../../data/SessionVar";
import { ExecuteDataSource } from "../../framework/AstroNetREST";
import { isNullOrEmptySpace } from "../../framework/Functions/AstroNetUtility";
import emotionNotGif from '../../../assets/emotionNot.gif'
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, openModal: false };
  }

  componentDidCatch(error, info) {

    let message = error.message
    let code = moment(new Date()).format('YYYYMMDDHHmmss')
    let view = false
    if (isNullOrEmptySpace(error.response) && !isNullOrEmptySpace(error.stack)) {
      this.registerErrorScreen(code, error)
    }

    let user = getUser();

    if (!isNullOrEmptySpace(user) && !isNullOrEmptySpace(user.UserGroup) && user.UserGroup.Master)
      view = true

    this.setState({
      hasError: true,
      message: message,
      error: error,
      info: info,
      codeError: code,
      view: view,
      openModal: false
    });


  }
  onClickBack() {
    window.location.href = "/admin"
  }

  registerErrorScreen(code, error) {
    var _this = this
    var obj = {
      DataSourceMethod: 'post',
      DataSourceUrl: getUrlBackEnd() + 'generic/log/register/' + code,
      DataSourceData: { errorStack: error.stack },
      DataSourceHeader: {
        'Content-Type': 'application/json',
        Authorization: getTokenLogin()
      }
    }
    ExecuteDataSource(obj).then(function (response) {
      console.error(`Erro Registrado Codigo, ${_this.state.codeError}`)
    }).catch(function (error) {
      console.error(error)
      _this.setState({ error: error })
    })
  }

  viewError() {
    this.setState({ openModal: true })
  }

  toggle() {
    this.setState({ openModal: false })
  }

  contentError() {
    if (!isNullOrEmptySpace(this.state.error.response) && this.state.error.response.status == 599) {
      return (
        <>
          <h4><b>{this.state.error.response.data.Message}</b></h4>
          <Label style={{ marginRight: "10px", marginTop:"10px" }}><b>Volte e realize o ajuste</b></Label>
          <FormGroup style={{ marginTop: "30px" }}>
            <Button color="primary" onClick={() => this.onClickBack()}>Voltar</Button>&nbsp;
          </FormGroup>
        </>)
    }
    else {
      return (
        <>
          <Label style={{ marginRight: "10px" }}><b>Procure seu Administrador</b> ou entre em contato conosco com o código do procedimento:</Label>
          <Label style={{ marginTop: "10px" }}><b>{this.state.codeError}</b></Label>
          <FormGroup style={{ marginTop: "30px" }}>
            <Button color="primary" onClick={() => this.onClickBack()}>Voltar</Button>&nbsp;
            {this.state.view ? <Button onClick={() => this.viewError()}>Ver</Button> : <></>}
          </FormGroup>
        </>
      )
    }
  }

  render() {
    let percWidth = window.innerWidth > 1200 ? "40%" : `${Math.round(100 - (1000 * 0.01))}%`
    if (!this.state.hasError)
      return this.props.children;
    var colprop = {
      xs: 6, //Extra large ≥1200px
      lg: 6, //Large ≥992px
      md: 6, //Medium ≥768px
      sm: 6, //Small ≥576px
    }
    var colpropIm = {
      xs: 2, //Extra large ≥1200px
      lg: 2, //Large ≥992px
      md: 2, //Medium ≥768px
      sm: 2, //Small ≥576px
    }

    return (
      <>
        <Container className="col-form-label" style={{ width: percWidth }}>
          <Card>
            <h4 style={{ marginTop: "20px", textAlign: "center" }}><b>Comportamento Inesperado</b></h4>
            <CardBody>
              <Row className="align-items-center">
                <Col {...colprop} ><img src={emotionNotGif} alt="logo" style={{ width: "75%" }} /></Col>
                <Col {...colprop} >
                  {this.contentError()}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.openModal} size="lg">
          <ModalHeader toggle={() => this.toggle()}>
            <Label>Detalhes do Bug</Label>
          </ModalHeader>
          <ModalBody>
            <Col {...colprop}>
              <Row><h2>{this.state.message}</h2></Row>
              <FormGroup>
                {JSON.stringify(this.state.info)}
              </FormGroup>
              <FormGroup>
                {JSON.stringify(this.state.error)}
              </FormGroup>
              <FormGroup>
                <h3>Response</h3>
                {JSON.stringify(this.state.error.response)}
              </FormGroup>
            </Col>
          </ModalBody>
        </Modal>
      </>)
  }
}

export default ErrorBoundary